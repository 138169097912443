import moment from 'moment-timezone'

export const compareDates = (date1, date2) => {
    var d1 = new Date(date1)
    var d2 = new Date(date2)
    return d1.getTime() <= d2.getTime()
}

export const dateNow = (daysToAdd = 0) => {
    const date = new Date()

    const totalDays = date.getDate() + daysToAdd
    const dateToSet = date.setDate(totalDays)
    const newDate = new Date(dateToSet)

    const dateISO = newDate.toISOString()

    const splitDate = dateISO.split('T')
    const formatDate = splitDate[0]
    return formatDate
}

export const dateAddDays = (currentDate, daysToAdd = 0) => {
    const date = new Date(currentDate)

    const totalDays = date.getDate() + daysToAdd
    const dateToSet = date.setDate(totalDays)
    const newDate = new Date(dateToSet)

    const dateISO = newDate.toISOString()

    const splitDate = dateISO.split('T')
    const formatDate = splitDate[0]
    return formatDate
}

export const daysLefts = (date1, date2) => {
    const oneDay = 24 * 60 * 60 * 1000
    const firstDate = new Date(date1)
    const secondDate = new Date(date2)
    const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay))

    return diffDays
}

export const setTimeZoneToDate = (
    date,
    format = 'ha',
    tz = 'America/Bogota'
) => {
    const currentDate = moment(date)
    const realDate = currentDate.tz(tz).format(format)
    return realDate
}
